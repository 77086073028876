<template>
  <div class="course-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>教学内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>课程管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" @click="add">添加课程</el-button>
        <el-button type="danger" @click="back">返回</el-button>
      </div>
      <div class="table-area">
        <el-table :data="pageData" border>
          <el-table-column fixed prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="m_id" label="唯一ID" width="120"></el-table-column>
          <el-table-column prop="title" label="课程名称"></el-table-column>
          <el-table-column prop="view_time" label="总观看时长">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain">{{ toTime(scope.row.view_time) }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="min_classify_title" label="归属小分类"></el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.m_id)">编辑</el-button>
              <el-button type="text" size="small" @click="del(scope.row.m_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Course',
  data () {
    return {
      pageData: []
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/course-list')
      if (res.code === 200) {
        this.pageData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    add () {
      this.$router.push('/admin/content/courses/course/add')
    },
    edit (mid) {
      this.$router.push('/admin/content/courses/course/edit/' + mid)
    },
    back () {
      this.$router.push('/admin/content/courses')
    },
    async del (mid) {
      const { data: res } = await this.$http.delete('/course', { params: { mid: mid } })
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 将时间转换为天
    toTime (machtime) {
      if (machtime > 0) {
        const s = Math.floor(machtime / 1) % 60
        machtime = Math.floor(machtime / 60)
        const i = machtime % 60
        machtime = Math.floor(machtime / 60)
        const h = machtime % 24
        machtime = Math.floor(machtime / 24)
        const d = machtime
        return d + ' 天 ' + h + ' 时 ' + i + ' 分 ' + s + ' 秒'
      } else {
        return '暂无观看时长'
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  margin-top: 30px;
}
.table-area{
  margin-top: 30px;
}
</style>
